import {AUTH} from "@/axios.js";
import axios from 'axios'

export default {
    async getTokenFromBe(redirect_uri, queryCode){
      // console.log("🚀 ~ getTokenFromBe ~ redirect_uri, queryCode", redirect_uri, queryCode)
        var response = await AUTH.post(`online-exam/auth/token`,
        {
            redirect_uri:redirect_uri,
            code: queryCode
        }
        )
        .then(res => {
            if (res.data) {
              console.log("getTokenFromBe(API):",res.data)
              return res.data
            }
        })
        .catch(e => {
            console.log(e);
            
        });
        return response
    },
    async getUserInGraph(accessToken){
      var config = {
        method: 'get',
        url: 'https://graph.microsoft.com/v1.0/me',
        headers: { 
          'SdkVersion': 'postman-graph/v2.0', 
          'Authorization': `Bearer ${accessToken}`
        }
      };

      let response = await axios(config)
      .then(function (response) {
        if(response.data){
          console.log(response.data);
          return response.data
        }
      })
      .catch(function (error) {
        console.log(error);
        return error
      });
      return response
    }
}