import axios from 'axios'
const basicAuth = 'Basic ' + btoa(process.env.VUE_APP_EXAM_API_USER + ':' + process.env.VUE_APP_EXAM_API_PASSWORD)
const basicAuth_REG = 'Basic ' + btoa(process.env.VUE_APP_REG_API_USER + ':' + process.env.VUE_APP_REG_API_PASSWORD)
export const HTTP = axios.create({
    baseURL: 'https://api.tlic.cmu.ac.th/exam-manager/',
    headers: {
        'Authorization': basicAuth
    },
});

export const HTTPV1DOT2 = axios.create({
    baseURL: process.env.VUE_APP_BACKEND_DOMAIN,
    headers: {
        'Authorization': basicAuth
    },
});

export const apiv2 = axios.create({
    baseURL: process.env.VUE_APP_BACKEND_DOMAIN,
    headers: {
        'Authorization': basicAuth
    },
});

export const REG = axios.create({
    baseURL: 'https://api.reg.cmu.ac.th/',
    headers: {
        'Authorization': basicAuth_REG
    },
});

export const AUTH = axios.create({
    baseURL: 'https://auth-tlic.tlic.cmu.ac.th/',
    headers: {
        'Authorization': basicAuth
    },
});

export const GRAPH = axios.create({
    baseURL: 'https://graph.microsoftonline.com/',
});

export const AZURE = axios.create({
    baseURL: 'https://login.microsoftonline.com/',
});