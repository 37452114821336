import Vue from "vue"
import Router from "vue-router"

//Token Check
import Auth from "@/services/Auth"

// Containers
const TheContainer = () =>
    import ("@/containers/TheContainer")

// Views
const Dashboard = () =>
    import ("@/views/Dashboard")

const PageNopermission = () =>
    import ("@/views/pages/PageNoPermission")

const Page404 = () =>
    import ("@/views/pages/Page404")

const Page500 = () =>
    import ("@/views/pages/Page500")

const Login = () =>
    import ("@/views/pages/Login")

const Register = () =>
    import ("@/views/pages/Register")

// Auth
const Callback = () =>
    import ("@/views/auth/Callback")
const Signout = () =>
    import ("@/views/auth/SignOut")
const Signin = () =>
    import ("@/views/auth/SignIn")

// Eventlist
const EventList = () =>
    import ("@/views/events/EventList")
const EventDetail = () =>
    import ("@/views/events/EventDetail")
    // Eventlist
const GeoMap = () =>
    import ("@/views/events/geoMap")
const GeoMap2 = () =>
    import ("@/views/events/geoMap2")

// Faculty Admin
const EventListFacultyAdmin = () =>
    import ("@/views/facultyadmin/EventListFacultyAdmin")

Vue.use(Router)

// export default new Router({
const router = new Router({
    mode: "history", // https://router.vuejs.org/api/#mode
    linkActiveClass: "active",
    scrollBehavior: () => ({ y: 0 }),
    routes: configRoutes(),
})

function configRoutes() {
    // console.log("Router");
    return [{
            path: "/",
            redirect: "/dashboard",
            name: "Home",
            component: TheContainer,
            children: [{
                    path: "dashboard",
                    name: "Dashboard",
                    component: Dashboard,
                },
                {
                    path: "events",
                    redirect: "/events",
                    name: "Events",
                    component: {
                        render(c) {
                            return c("router-view")
                        },
                    },
                    children: [{
                            path: "",
                            name: "EventList",
                            component: EventList,
                        },
                        {
                            path: ":eventid/detail",
                            name: "EventDetail",
                            meta: {
                                label: "Event Details",
                            },
                            component: EventDetail,
                        },
                        {
                            path: ":eventid/map",
                            name: "GeoMap",
                            meta: {
                                label: "Geo Mapping",
                            },
                            component: GeoMap,
                        },
                        {
                            path: ":eventid/map2",
                            name: "GeoMap",
                            meta: {
                                label: "Geo Mapping",
                            },
                            component: GeoMap2,
                        },
                    ],
                },
                {
                    path: "facultyeventlist",
                    name: "Faculty Event List",
                    component: EventListFacultyAdmin,
                },
            ],
        },
        {
            path: "/pages",
            redirect: "/pages/404",
            name: "Pages",
            component: {
                render(c) {
                    return c("router-view")
                },
            },
            children: [{
                    path: "nopermission",
                    name: "Nopermission",
                    component: PageNopermission,
                },
                {
                    path: "404",
                    name: "Page404",
                    component: Page404,
                },
                {
                    path: "500",
                    name: "Page500",
                    component: Page500,
                },
                {
                    path: "login",
                    name: "Login",
                    component: Login,
                },
                {
                    path: "register",
                    name: "Register",
                    component: Register,
                },
            ],
        },
        {
            path: "/callback",
            component: {
                render(c) {
                    return c("router-view")
                },
            },
            children: [{
                path: "/",
                name: "Callback",
                component: Callback,
            }, ],
        },
        {
            path: "/signout",
            component: {
                render(c) {
                    return c("router-view")
                },
            },
            children: [{
                path: "/",
                name: "Signout",
                component: Signout,
            }, ],
        },
        {
            path: "/signin",
            component: {
                render(c) {
                    return c("router-view")
                },
            },
            children: [{
                path: "/",
                name: "Signin",
                component: Signin,
            }, ],
        },
        {
            path: "*",
            component: {
                render(c) {
                    return c("router-view")
                },
            },
            children: [{
                path: "/",
                name: "404",
                component: Page404,
            }, ],
        },
    ]
}

router.beforeEach((to, from, next) => {
    console.log("router:",to)
    let Acctype = localStorage.getItem("AccType")
    let token = localStorage.getItem("access_token")
    if (token !== null) {
        Auth.getUserInGraph(token).then((user) => {
            if (user instanceof Error) {
                localStorage.removeItem("access_token")
                localStorage.removeItem("email")
                localStorage.removeItem("name")
                localStorage.removeItem("AccType")
                localStorage.removeItem("code")
                localStorage.removeItem("userProfile")
                localStorage.removeItem("Admin")
                localStorage.setItem("beforepath",to.fullPath)
                next({ name: "Signin" })
            }
        })
    }
    if (to.name !== "Signin" && to.name !== "Callback" && to.name !== "Page500" && token === null) {
        localStorage.setItem("beforepath",to.fullPath)
        next({ name: "Signin" })
    } else if (to.name === "Dashboard" || to.name === "EventList" || to.name === "EventDetail") {
        if (Acctype !== "Employee") {
            next({ name: "Nopermission" })
        } else {
            next()
        }
    } else {
        next()
    }
})

export default router